import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

function NavBar() {
  const [currentPath, setCurrentPath] = useState('/');
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  useEffect(() => {
    function onScroll() {
      const currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      if (currentPosition > scrollTop) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener('scroll', onScroll);
    return window.addEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <Navbar
      className={`navbar ${scrolling ? 'scrolling' : ''}`}
      collapseOnSelect
      expand="lg"
      sticky="top"
      onToggle={() => setIsCollapsed(!isCollapsed)}
    >
      <Container>
        <AniLink cover to="/" bg="#3486fb">
          <Navbar.Brand href="#home">
            Dr Gio Valiante
            <span>Performance Psychologist</span>
          </Navbar.Brand>
        </AniLink>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className={isCollapsed ? 'closed' : ''}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <AniLink
              className={currentPath === '/about' ? 'active' : ''}
              cover
              to="/about"
              bg="#3486fb"
            >
              About
            </AniLink>
            <AniLink
              className={currentPath === '/process' ? 'active' : ''}
              cover
              to="/process"
              bg="#3486fb"
            >
              Process
            </AniLink>
            <AniLink
              className={currentPath === '/results' ? 'active' : ''}
              cover
              to="/results"
              bg="#3486fb"
            >
              Results
            </AniLink>
            <AniLink
              className={currentPath === '/contact' ? 'active' : ''}
              cover
              to="/contact"
              bg="#3486fb"
            >
              Contact
            </AniLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
