import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import NavBar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/main.scss';

function Layout({ children, title, description, className }) {
  return (
    <div className={className}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Gio Valiante | Performance Psychologist'}</title>
        <html lang="en" />
        <meta name="description" content={description || 'Dr. Gio Valiante is regarded as one of the most successful performance coaches in the world. He graduated with Bachelors and Masters degrees from the University of Florida, and a Ph.D. from Emory University where he studied Social Cognitive Theory under the world-renowned professor, Frank Pajares.'} />
      </Helmet>
      <NavBar />
      <main>{children}</main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default Layout;
